import React, { FC } from 'react';
import classNames from 'classnames';

import { IRadioButtonCustom } from './models';
import './RadioButtonCustom.scss';

const RadioButtonCustom: FC<IRadioButtonCustom> = ({
  onClick,
  labelText,
  name,
  id,
  isChecked,
  isDisabled,
  inputColor,
}) => {
  const labelClasses = classNames('nf-radio-custom', {
    'nf-radio-custom--disabled': isDisabled,
  });

  const defaultInputColorClass = inputColor
    ? `${inputColor}-default-bg ${inputColor}-default-border`
    : '';
  const inputClasses = classNames('nf-radio-custom__fake-input', {
    [defaultInputColorClass]: defaultInputColorClass,
  });

  return (
    <label className={labelClasses} htmlFor={`nf-radio-custom-${id}`}>
      <input
        className="nf-radio-custom__input"
        type="radio"
        onClick={onClick}
        checked={isChecked}
        id={`nf-radio-custom-${id}`}
        name={name}
        readOnly
      />
      <span className={inputClasses} />
      <span className="nf-radio-custom__fake-label">{labelText}</span>
    </label>
  );
};

export default RadioButtonCustom;
