import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import { getDOMRectById } from 'utils/browser';

import IconCustom from 'components/common/IconCustom';
import RadioButtonCustom from 'components/common/RadioButtonCustom';

import { ALGOLIA_FILTERS_IDS } from 'components/AlgoliaFilters/constants';
import ModuleHelpers from 'components/AlgoliaFilters/moduleHelpers';

import './CategoryFilters.scss';
import { IActiveItem, IPropsCategoryFilters } from './model';
import Helpers from './helpers';

const CategoryFilters: FC<IPropsCategoryFilters> = (props): ReactElement => {
  const [activeItem, setActiveItem] = useState<IActiveItem | null>(null);
  const [tempActiveCategoryId, setTempActiveCategoryId] = useState<number | null>(null);
  const { categories, activeCategoryId, customGlobalStyles, masterPageData, isMobile } = props;

  const handleActiveCategoryId = useCallback(
    (categoryId: number) => () => {
      const parentData = getDOMRectById(ALGOLIA_FILTERS_IDS.categoryFiltersList);
      const currentBtnData = getDOMRectById(String(categoryId));

      if (!currentBtnData || !parentData) {
        return;
      }

      setActiveItem({ ...Helpers.getCurrentPosition(currentBtnData, parentData), id: categoryId });
    },
    []
  );

  useEffect(() => {
    if (!activeCategoryId) {
      return;
    }

    const handleOnLoad = handleActiveCategoryId(activeCategoryId);

    // For handling the switching toggler
    if (window.document.readyState === 'complete') {
      handleOnLoad();

      return;
    }

    window.addEventListener('load', handleOnLoad);

    return () => {
      window.removeEventListener('load', handleOnLoad);
    };
  }, [activeCategoryId]);

  const handleBtnClick = useCallback(
    (id: number, link: string) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const parentData = getDOMRectById(ALGOLIA_FILTERS_IDS.categoryFiltersList);
      const currentBtnData = event.currentTarget.getBoundingClientRect();

      if (!parentData) {
        return;
      }

      setActiveItem({ ...Helpers.getCurrentPosition(currentBtnData, parentData), id });

      setTimeout(() => {
        navigate(`${link}`);
      }, 410);
    },
    [activeCategoryId]
  );

  const handleRadioBtnClick = useCallback(
    (id: number, link: string) => () => {
      setTempActiveCategoryId(activeCategoryId === id ? 0 : id);

      setTimeout(() => {
        navigate(activeCategoryId === id ? `${masterPageData.link}` : `${link}`);
      }, 410);
    },
    [activeCategoryId]
  );

  const { defaultTextColorClass, defaultBgColorClass } = ModuleHelpers.getCustomClassNames(
    customGlobalStyles
  );
  const controlsClasses = classNames('', {
    [defaultTextColorClass || '']: defaultTextColorClass,
    [defaultBgColorClass || '']: defaultBgColorClass,
  });

  const handleUnapply = useCallback(() => {
    navigate(masterPageData.link);
  }, [masterPageData]);

  return (
    <div data-test="CategoryFilters" className="nf-category-filters">
      <div className="list" id={ALGOLIA_FILTERS_IDS.categoryFiltersList}>
        {!isMobile && activeItem ? (
          <div
            className={`toggler ${controlsClasses}`}
            style={{ left: activeItem?.left, width: activeItem?.width }}
          >
            <IconCustom icon={`close_icon-2 ${controlsClasses}`} onClick={handleUnapply} />
          </div>
        ) : null}

        {isMobile
          ? categories.map((item: AppFilters.IFilterCategory) => (
              <RadioButtonCustom
                key={item.id}
                id={item.id}
                labelText={item.title}
                name="category"
                isChecked={
                  tempActiveCategoryId !== null
                    ? tempActiveCategoryId === item.id
                    : activeItem?.id === item.id || activeCategoryId === item.id
                }
                onClick={handleRadioBtnClick(item.id, item.relatedPageLink)}
                inputColor={customGlobalStyles?.defaultControlsBgColor}
              />
            ))
          : categories.map((item: AppFilters.IFilterCategory) => (
              <button
                key={item.id}
                id={String(item.id)}
                type="button"
                className={`item ${
                  activeItem?.id === item.id ? 'item-active' : ''
                } ${controlsClasses}`}
                onClick={handleBtnClick(item.id, item.relatedPageLink)}
              >
                {item.title}
              </button>
            ))}
      </div>
    </div>
  );
};

export default CategoryFilters;
