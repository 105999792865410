import React, { FC } from 'react';
import classNames from 'classnames';

import { ICheckBoxCustom } from './models';
import './CheckBoxCustom.scss';
import DangerouslySetInnerHtml from '../DangerouslySetInnerHtml';

const CheckBoxCustom: FC<ICheckBoxCustom> = ({
  isChecked,
  isDisabled,
  id,
  name,
  labelText,
  onChange,
  bgColor,
  signColor,
}) => {
  const classes = classNames('nf-checkbox-custom', {
    'nf-checkbox-custom': isDisabled,
  });

  const defaultInputBgColorClass = bgColor ? `${bgColor}-default-bg ${bgColor}-default-border` : '';
  const defaultInputSignColorClass = signColor ? `${signColor}-default-border` : '';
  const inputClasses = classNames('nf-checkbox-custom__fake-input', {
    [defaultInputBgColorClass]: defaultInputBgColorClass,
  });
  const inputSignClasses = classNames('nf-checkbox-custom__fake-input__sign', {
    [defaultInputSignColorClass]: defaultInputSignColorClass,
  });

  return (
    <div className={classes}>
      <input
        className="nf-checkbox-custom__input"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        id={`nf-checkbox-custom-${id}`}
        name={name}
      />
      <label htmlFor={`nf-checkbox-custom-${id}`}>
        <span className={inputClasses}>
          <span className={inputSignClasses} />
        </span>
        <DangerouslySetInnerHtml html={labelText} className="nf-checkbox-custom__fake-label" />
      </label>
    </div>
  );
};

export default CheckBoxCustom;
