import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';

import ModuleHelpers from 'components/AlgoliaFilters/moduleHelpers';

import './ChosenFiltersList.scss';
import { IPropsChosenFiltersList } from './model';

const ChosenFiltersList: FC<IPropsChosenFiltersList> = (props): ReactElement => {
  const {
    items,
    handleRemoveSelectionData,
    customGlobalStyles,
    removeAppliedFilterAriaLabel,
  } = props;

  const { defaultTextColorClass, defaultBgColorClass } = ModuleHelpers.getCustomClassNames(
    customGlobalStyles
  );
  const defaultControlsBorderColor = customGlobalStyles?.defaultControlsBgColor || '';

  const controlsClasses = classNames('filter-item', {
    [defaultTextColorClass || '']: defaultTextColorClass,
    [defaultBgColorClass || '']: defaultBgColorClass,
    [`${defaultControlsBorderColor}-default-border`]: defaultControlsBorderColor,
  });
  const removeBtnClasses = classNames('remove-btn', {
    [defaultTextColorClass || '']: defaultTextColorClass,
  });

  return (
    <div data-test="ChosenFiltersList" className="nf-chosen-filters-list">
      {items.map((item: AppFilters.IChosenFilterItemData) => {
        return (
          <div key={`${item.sectionId}_${item.itemId}`} className={controlsClasses}>
            <span className="filter-item-title">{item.title}</span>
            <button
              className={removeBtnClasses}
              type="button"
              aria-label={removeAppliedFilterAriaLabel}
              onClick={handleRemoveSelectionData(item.sectionId, item.itemId)}
            >
              <IconCustom icon="close_icon-2" />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ChosenFiltersList;
