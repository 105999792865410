const getCurrentPosition = (
  currentBtnData: DOMRect,
  parentData: DOMRect
): { left: number; width: number } => {
  return {
    left: currentBtnData.left - parentData.left - 1,
    width: currentBtnData.width,
  };
};

const Helpers = {
  getCurrentPosition,
};

export default Helpers;
