import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementListProvided } from 'react-instantsearch-core';
import classNames from 'classnames';

import CheckBoxCustom from 'components/common/CheckBoxCustom';
import NFAccordion from 'components/common/NFAccordion';
import RadioButtonCustom from 'components/common/RadioButtonCustom';
import { ALGOLIA_FILTERS_NUMBERS } from 'components/AlgoliaFilters/constants';

import './CustomRefinementList.scss';
import { IPropsCustomRefinementList } from './model';

const { maxFilterItemsToShow } = ALGOLIA_FILTERS_NUMBERS;

export const CustomRefinementList: FC<IPropsCustomRefinementList> = ({
  items,
  refine,
  filterItems,
  sectionId,
  variant,
  title,
  isOpen,
  checkboxStyles,
  customGlobalStyles,
  filterButtonsTexts,
  chosenFilterIds,
  handleRemoveSelectionData,
}): ReactElement | null => {
  const [maxItemsNumber, setMaxItemsNumber] = useState<number>(maxFilterItemsToShow);
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<number>(isOpen ? 0 : -1);

  const uncheckSelectedDisabledItems = useCallback(
    (itemsToCheck: RefinementListProvided['items']) => {
      itemsToCheck.forEach((item) => {
        if (item.count === 0 && item.isRefined) {
          handleRemoveSelectionData(sectionId, parseInt(item.label, 10))();
        }
      });
    },
    []
  );

  // check provided items
  useEffect(() => {
    uncheckSelectedDisabledItems(items);
  }, [items]);

  const onChangeAccordionCallback = useCallback(({ selectedIndex }) => {
    setSelectedAccordionIndex(selectedIndex);
  }, []);

  const handleMaxItemsNumber = useCallback(() => {
    setMaxItemsNumber((oldValue: number) =>
      oldValue >= filterItems.length ? maxFilterItemsToShow : oldValue + maxFilterItemsToShow
    );
  }, [filterItems.length]);

  const handleTransformItems = useCallback(
    (
      refinementItems: AppFilters.IAlgoliaRefinementItem[],
      cmsItems: AppFilters.IFilterItem[]
    ): AppFilters.IRefinementItem[] => {
      const data = cmsItems.map((filterItem: AppFilters.IFilterItem) => {
        const refinementItem = refinementItems.find((item) => item.label === String(filterItem.id));

        return {
          ...filterItem,
          count: refinementItem?.count || 0,
          isRefined: refinementItem?.isRefined || false,
          label: filterItem.title,
          value: refinementItem?.value || [String(filterItem.id)],
        };
      });

      return data;
    },
    []
  );

  const handleOnChange = useCallback(
    (item: AppFilters.IRefinementItem) => () => {
      if (variant === 'single') {
        refine([String(item.id)]);

        return;
      }

      refine(item.value);
    },
    [refine, variant]
  );

  const itemsToShow =
    filterItems.length > maxItemsNumber ? filterItems.slice(0, maxItemsNumber) : filterItems;
  const itemsToRender = useMemo(() => handleTransformItems(items, itemsToShow), [
    items,
    itemsToShow,
  ]);

  const seeMoreBtnDefaultTextColorClass = customGlobalStyles?.defaultControlsBgColor
    ? `${customGlobalStyles.defaultControlsBgColor}-default-text`
    : '';
  const seeMoreBtnClasses = classNames('custom-refinement-list__btn', {
    [seeMoreBtnDefaultTextColorClass || '']: seeMoreBtnDefaultTextColorClass,
  });

  return (
    <div data-test={`CustomRefinementList-${title}`} className="custom-refinement-list">
      <NFAccordion
        onChange={onChangeAccordionCallback}
        changeOnClick={false}
        items={[
          {
            header: title,
            content: (
              <div>
                {itemsToRender.map((item: AppFilters.IRefinementItem) => {
                  if (variant === 'multi') {
                    return (
                      <CheckBoxCustom
                        key={item.id}
                        isChecked={chosenFilterIds?.includes(String(item.id))}
                        labelText={item.title}
                        id={item.title}
                        isDisabled={item.count === 0}
                        onChange={handleOnChange(item)}
                        bgColor={
                          checkboxStyles?.bgColor || customGlobalStyles?.defaultControlsBgColor
                        }
                        signColor={
                          checkboxStyles?.signColor || customGlobalStyles?.defaultControlsTextColor
                        }
                      />
                    );
                  }

                  return (
                    <RadioButtonCustom
                      key={item.id}
                      id={item.id}
                      labelText={item.title}
                      name={sectionId}
                      isChecked={chosenFilterIds?.includes(String(item.id))}
                      isDisabled={item.count === 0}
                      onClick={handleOnChange(item)}
                      inputColor={
                        checkboxStyles?.bgColor || customGlobalStyles?.defaultControlsBgColor
                      }
                    />
                  );
                })}

                {filterItems.length > maxFilterItemsToShow ? (
                  <div className="nf-filter-section__buttons">
                    <button
                      type="button"
                      className={seeMoreBtnClasses}
                      onClick={handleMaxItemsNumber}
                    >
                      {itemsToShow.length >= filterItems.length
                        ? filterButtonsTexts.seeLessButtonText
                        : filterButtonsTexts.seeMoreButtonText}
                    </button>
                  </div>
                ) : null}
              </div>
            ),
          },
        ]}
        selectedIndex={selectedAccordionIndex}
      />
    </div>
  );
};

const ConnectedCustomRefinementList = connectRefinementList(CustomRefinementList);

export default ConnectedCustomRefinementList;
