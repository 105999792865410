import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import ConnectedCustomRefinementList from 'components/AlgoliaFilters/CustomRefinementList';

import { IPropsFilterSection } from './model';

const FilterSection: FC<IPropsFilterSection> = ({
  attribute,
  operator,
  title,
  filterItems,
  isOpen,
  checkboxStyles,
  customGlobalStyles,
  filterButtonsTexts,
  variant,
  chosenFilterIds,
  handleRemoveSelectionData,
  showFilters,
}): ReactElement | null => {
  const classes = classNames('filter-section', { showFilters });

  return (
    <div data-test={`FilterSection-${title}`} className={classes}>
      <ConnectedCustomRefinementList
        attribute={attribute}
        operator={operator}
        filterItems={filterItems}
        sectionId={attribute}
        variant={variant}
        isOpen={isOpen}
        title={title}
        checkboxStyles={checkboxStyles}
        customGlobalStyles={customGlobalStyles}
        filterButtonsTexts={filterButtonsTexts}
        chosenFilterIds={chosenFilterIds}
        handleRemoveSelectionData={handleRemoveSelectionData}
        limit={30}
      />
    </div>
  );
};

export default FilterSection;
