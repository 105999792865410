import React, { FC, ReactElement } from 'react';

import './ChosenFiltersTitle.scss';
import { IPropsChosenFiltersTitle } from './model';

const ChosenFiltersTitle: FC<IPropsChosenFiltersTitle> = (props): ReactElement => {
  const { isActive, filtersSelectLabel, filtersHaveSelectedLabel } = props;

  return (
    <div data-test="ChosenFiltersTitle" className="nf-chosen-filters-title">
      <strong className="title">{isActive ? filtersHaveSelectedLabel : filtersSelectLabel}</strong>
    </div>
  );
};

export default ChosenFiltersTitle;
