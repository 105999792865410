import { getDOMRectById } from 'utils/browser';
import ELEMENTS from 'utils/constants';

import { IStylesData } from './model';

const getMobileStylesData = (): IStylesData | null => {
  const headerData = getDOMRectById(ELEMENTS.HEADER_ID);
  if (!headerData) {
    return null;
  }

  return {
    top: `${headerData.height}px`,
    height: `calc(100vh - ${headerData.height}px)`,
    width: `${headerData.width}px`,
  };
};

const getDesktopStylesData = (idInMainDOM: string): IStylesData | null => {
  const headerData = getDOMRectById(ELEMENTS.HEADER_ID);
  if (!headerData) {
    return null;
  }

  const mainDOMData = getDOMRectById(ELEMENTS.CONTENT_ID);
  const mainDOMChildren = document.getElementById(ELEMENTS.CONTENT_ID)?.children;
  let upperElementsHeight = 0;
  let holderElementGap = 0;

  if (!mainDOMChildren || !mainDOMData) {
    return null;
  }

  const mainDOMChildrenArray = Array.prototype.slice.call(mainDOMChildren);
  mainDOMChildrenArray.some((item) => {
    if (item.attributes.id && item.attributes.id.nodeValue === idInMainDOM) {
      const styles = window.getComputedStyle(item, null);
      holderElementGap = parseInt(styles.paddingTop, 10);

      return true;
    }
    upperElementsHeight += item.getBoundingClientRect().height;

    return false;
  });

  return {
    top: '0px',
    height: `${mainDOMData.height - upperElementsHeight - holderElementGap - headerData.height}px`,
    width: 'auto',
  };
};

const Helpers = {
  getMobileStylesData,
  getDesktopStylesData,
};

export default Helpers;
