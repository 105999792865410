import React, {
  FC,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { getDOMRectById } from 'utils/browser';
import ELEMENTS from 'utils/constants';

import IconCustom from 'components/common/IconCustom';

import ModuleHelpers from 'components/AlgoliaFilters/moduleHelpers';

import './FiltersToggler.scss';
import { IPropsFiltersToggler } from './model';

const FiltersToggler: FC<IPropsFiltersToggler> = (props): ReactElement => {
  const [isSticky, setSticky] = useState(false);
  const wrapperRef: RefObject<HTMLDivElement> = useRef(null);
  const {
    id,
    handleSidebarOpen,
    filtersNumber,
    customGlobalStyles,
    isMobile,
    filtersTogglerButtonText,
  } = props;

  const handleScroll = useCallback(() => {
    let localIsSticky = false;

    return () => {
      const headerData = getDOMRectById(ELEMENTS.HEADER_ID);
      if (!wrapperRef?.current || !headerData) {
        return;
      }
      const position = wrapperRef.current.getBoundingClientRect().top;
      const divergence = 2;

      if (position <= headerData.height + divergence && !localIsSticky) {
        setSticky(true);
        localIsSticky = true;
      }

      if (position > headerData.height + divergence && localIsSticky) {
        setSticky(false);
        localIsSticky = false;
      }
    };
  }, []);

  useEffect(() => {
    const handleOnScroll = handleScroll();
    if (!isMobile) {
      window.removeEventListener('scroll', handleOnScroll);

      return;
    }

    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, [isMobile]);

  const { defaultTextColorClass, defaultBgColorClass } = ModuleHelpers.getCustomClassNames(
    customGlobalStyles
  );
  const numberClasses = classNames('nf-filters-toggler__filters-number', {
    [defaultTextColorClass || '']: defaultTextColorClass,
    [defaultBgColorClass || '']: defaultBgColorClass,
  });

  const togglerBorderClass = customGlobalStyles?.defaultControlsBgColor
    ? `${customGlobalStyles?.defaultControlsBgColor}-default-border`
    : '';
  const togglerClasses = classNames('nf-filters-toggler__toggle-btn', {
    [defaultTextColorClass || '']: defaultTextColorClass,
    [`${defaultBgColorClass} ${togglerBorderClass}` || '']: defaultBgColorClass,
  });

  return (
    <div data-test="FiltersToggler" id={id} className="nf-filters-toggler" ref={wrapperRef}>
      <div
        className={classNames('filters-toggler-sticky-wrapper', {
          sticky: isSticky && isMobile,
        })}
      >
        <button className={togglerClasses} type="button" onClick={handleSidebarOpen}>
          <IconCustom icon="filters_icon" />
          <span>{filtersTogglerButtonText}</span>
          {filtersNumber ? <span className={numberClasses}>{filtersNumber}</span> : null}
        </button>
      </div>
    </div>
  );
};

export default FiltersToggler;
