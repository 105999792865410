import { FILTER_NAVIGATION_PARAMS } from 'utils/algolia/constants';
import {
  generateLocationQueryString,
  generateUrlParamsArray,
  getLocationQueryStringParam,
  getRemovedParamFromLocationQueryString,
  setUrl,
} from 'utils/browser';

import { ALGOLIA_FILTERS_PARAMS, DefaultSearchState } from './constants';
import { IChosenFilterItemsResult } from './model';

const getOptionalFiltersParams = (refinementList: AppFilters.IAlgoliaRefinementList): string => {
  const allSelectedIds: string[] = [];

  Object.values(refinementList).forEach((items) => {
    if (items?.length) {
      items.forEach((item) => {
        allSelectedIds.push(item);
      });
    }
  });

  let response = allSelectedIds.length ? '[[' : '';

  if (response) {
    allSelectedIds.forEach((id, index) => {
      response += `${index === 0 ? '"' : `, "`}${FILTER_NAVIGATION_PARAMS.preferredFacet}:${id}"`;
    });

    response += ']]';
  }

  return response;
};

const getDefaultFiltersParams = (
  categoryFacetName: string,
  activeCategoryId: number | null,
  lang: string,
  additionalFilterParams?: string
): string => {
  return `${activeCategoryId ? `${categoryFacetName}:${activeCategoryId} AND ` : ''}lang:${lang}${
    additionalFilterParams || ''
  }`;
};

const getActiveCategoryIdToSet = (
  categories: AppFilters.IFilterCategory[],
  currentPageId: string
): number | null => {
  const pageCategoryData = categories.find((item) => currentPageId.includes(item.relatedPage));
  if (!pageCategoryData || !pageCategoryData.id) {
    return null;
  }

  return pageCategoryData.id;
};

const isActiveRefinement = (
  refinementList: AppFilters.IAlgoliaRefinementList,
  refinementId: string,
  sectionId: string
): boolean => {
  return Boolean(refinementList?.[sectionId]?.includes(refinementId));
};

const getChosenFilterItems = (
  sections: AppFilters.IFilterSection[],
  refinementList: AppFilters.IAlgoliaRefinementList | undefined
): IChosenFilterItemsResult => {
  const data: IChosenFilterItemsResult = {
    chosenFilterItems: [],
    chosenFilterIds: [],
  };

  if (!refinementList) {
    return data;
  }

  sections.forEach((section: AppFilters.IFilterSection) => {
    section.filterItems.forEach((item: AppFilters.IFilterItem) => {
      if (!isActiveRefinement(refinementList, String(item.id), section.attributeForFaceting)) {
        return;
      }

      data.chosenFilterItems.push({
        title: item.title,
        itemId: item.id,
        sectionId: section.attributeForFaceting,
      });
      data.chosenFilterIds.push(String(item.id));
    });
  });

  return data;
};

const removeItemInRefinementList = (
  refinementIds: string[] | undefined,
  refinementId: string
): string[] | null => {
  if (!refinementIds?.length) {
    return null;
  }

  const data = refinementIds.filter((item: string) => {
    return item !== refinementId;
  });

  if (!data.length) {
    return null;
  }

  return data;
};

const encodeFilterIdParam = ({ sectionId, itemId }: AppFilters.IFilterIdUrlParam): string => {
  return `${sectionId}-${itemId}`;
};

const decodeFilterIdParam = (param: string): AppFilters.IFilterIdUrlParam => {
  const rawData = param.split('-');

  return { sectionId: rawData[0], itemId: rawData[1] };
};

const setFilteredUrlParams = (
  sections: AppFilters.IFilterSection[],
  refinementList: AppFilters.IAlgoliaRefinementList | undefined
): void => {
  const { chosenFilterItems } = getChosenFilterItems(sections, refinementList);
  const chosenFilterIds = chosenFilterItems.map((item: AppFilters.IChosenFilterItemData) => {
    return encodeFilterIdParam({ sectionId: item.sectionId, itemId: String(item.itemId) });
  });

  let url: string | null = '';
  if (chosenFilterIds.length) {
    url = generateLocationQueryString(
      ALGOLIA_FILTERS_PARAMS.filterSection,
      generateUrlParamsArray(chosenFilterIds)
    );
  } else {
    url = getRemovedParamFromLocationQueryString(ALGOLIA_FILTERS_PARAMS.filterSection);
  }

  setUrl(url || '');
};

const getSavedSelectionDataToSet = (): AppFilters.IAlgoliaSearchState | null => {
  const chosenSavedFilterIds = getLocationQueryStringParam(
    ALGOLIA_FILTERS_PARAMS.filterSection,
    'array'
  );

  if (!chosenSavedFilterIds.length || !chosenSavedFilterIds[0].length) {
    return null;
  }

  const dataToSet = chosenSavedFilterIds[0].map((item: string) => {
    return decodeFilterIdParam(item);
  });

  const selectionDataToSet = { ...DefaultSearchState, refinementList: {} };
  dataToSet.forEach((item: AppFilters.IFilterIdUrlParam) => {
    if (!selectionDataToSet.refinementList[item.sectionId]) {
      selectionDataToSet.refinementList[item.sectionId] = [];
    }

    selectionDataToSet.refinementList[item.sectionId].push(item.itemId);
  });

  return selectionDataToSet;
};

const Helpers = {
  getOptionalFiltersParams,
  getActiveCategoryIdToSet,
  getChosenFilterItems,
  removeItemInRefinementList,
  setFilteredUrlParams,
  getSavedSelectionDataToSet,
  getDefaultFiltersParams,
};

export default Helpers;
