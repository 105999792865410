import React, { FC, ReactElement, useCallback, useState } from 'react';

import NFAccordion from 'components/common/NFAccordion';

import './CategorySection.scss';
import { IPropsCategorySection } from './model';

const CategorySection: FC<IPropsCategorySection> = (props): ReactElement => {
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<number>(0);
  const { header, content } = props;

  const onChangeCallback = useCallback(({ selectedIndex }) => {
    setSelectedAccordionIndex(selectedIndex);
  }, []);

  return (
    <div className="nf-category-section">
      <NFAccordion
        onChange={onChangeCallback}
        changeOnClick={false}
        items={[
          {
            header,
            content,
          },
        ]}
        selectedIndex={selectedAccordionIndex}
      />
    </div>
  );
};

export default CategorySection;
