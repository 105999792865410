const getCustomClassNames = (
  globalStyles?: AppFilters.ICustomGlobalStyles | null,
  localStyles?: AppFilters.ICustomGlobalStyles
): AppFilters.ICustomGlobalStylesClassNames => {
  const defaultControlsBgColor =
    localStyles?.defaultControlsBgColor || globalStyles?.defaultControlsBgColor || '';
  const defaultControlsTextColor =
    localStyles?.defaultControlsTextColor || globalStyles?.defaultControlsTextColor || '';

  const data = {
    defaultBgColorClass: defaultControlsBgColor ? `${defaultControlsBgColor}-default-bg` : '',
    defaultTextColorClass: defaultControlsTextColor
      ? `${defaultControlsTextColor}-default-text`
      : '',
  };

  return data;
};

const ModuleHelpers = {
  getCustomClassNames,
};

export default ModuleHelpers;
